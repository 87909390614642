<template>
  <div class="sellers_auth_block">
    <div class="container">
      <div class="min_container">
        <form @submit.prevent="sellersAuthFunc">
          <div class="sellers-auth">
            <div class="heeader_form mt-0">Вход в личный кабинет продавца</div>
            <p>
              Введите подтвержденную почту для входа в личный аккаунт продавца и сохранения бонусов за продажи.<br/>
              Ссылка с доступами в личный кабинет будет отправлена вам на почту.<br/>
              Успешных продаж!
            </p>
            <!-- <label for="">Озеро</label>
            <multiselect
              class='lake_multiselect'
              v-model="value"
              :options="options"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Выберите озеро">
            </multiselect> -->
            <p><b>Для подтверждения почты или создания аккаунта, свяжитесь с администратором <a href="tel:+77010555585">+7
              (701) 055-55-85</a></b></p>
            <label for="mail">Электронный адрес</label>
            <input
                    id="mail"
                    type="email"
                    required
                    placeholder="Укажите Ваш электронный адрес"
                    v-model="sellerData.email"
                    class="form-control form-input"
            >
            <div
                    v-if="errors['email'] && error"
                    class="help help-error help-repeat--active"
            >
              {{ errors['email'][0] }}
            </div>
          </div>

          <div class="sellers-auth-footer">
            <button
                    type="submit"
                    class="btn_pry"
                    :disabled="loading || success === true || success === false"
                    :class="{'btn_success': success === true , 'btn_error': errorStatus}"
            >
              {{ authMessage }}
              <svg v-if="success === true" width="25" height="17" viewBox="0 0 25 17" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8818 0L24.2507 2.52861L9.07907 16.7418L6.7102 14.2132L21.8818 0Z" fill="white"/>
                <rect x="11.6006" y="14.4204" width="3.46488" height="13.3904"
                      transform="rotate(137.501 11.6006 14.4204)" fill="white"/>
              </svg>
              <svg v-if="success === false" width="18" height="18" viewBox="0 0 18 18" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <rect x="15.5498" y="0.849854" width="3.46488" height="20.7893" transform="rotate(45 15.5498 0.849854)"
                      fill="white"/>
                <rect x="18" y="15.5498" width="3.46488" height="20.7893" transform="rotate(135 18 15.5498)"
                      fill="white"/>
              </svg>
            </button>
            <div class="sellers-auth-message">
              <p
                      v-if="success === true"
                      class="sellers-auth-success"
              >
                Ccылка для входа отправлена вам на почту.<br/>Проверьте свою почту
              </p>
              <p
                      v-if="success === false"
                      class="sellers-auth-error"
              >
                Аккаунт продавца не найден или не подтвержден.<br/>
                <span>Свяжитесь с администратором <a href="tel:+77078144498">+7 (707) 814-44-98</a></span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
    import {auth} from '@/api-requests/shopApi'
    // import Multiselect from 'vue-multiselect'

    export default {
        name: 'Auth',
        data: () => ({
            sellerData: {
                email: '',
            },
            authMessage: 'Продолжить',
            value: '',
            options: [
                'Рыбацкая усадьба',
                'Рыбацкая усадьба2',
                'Рыбацкая усадьба3'
            ],
            errors: {
                email: [],
            },
            errorStatus: false,
            success: '',
            error: false,
            loading: false,
        }),
        watch: {
            'sellerData.email': function (value) {
                if (this.success === false) {
                    this.success = ''
                    this.authMessage = 'Продолжить'
                    this.errorStatus = false
                    this.errors.email = []
                }
            },
        },
        methods: {
            async sellersAuthFunc() {
                const {email} = this.sellerData

                if (email) {
                    this.loading = true
                    this.authMessage = 'Ожидание'
                    const authRes = await auth(email)
                    this.authMessage = ''
                    if (authRes.data.success == false) {
                        this.errorStatus = true
                        this.success = false
                    } else {
                        this.errorStatus = false
                        this.success = true
                    }

                    this.loading = false
                } else {
                    this.errors.email.push('Необходимо ввести почту')
                }
            }
        },
    }
</script>