import { shopHttp } from '../http/axios'

const lakeNumber = 1

export async function auth(email) {
  const authRes = await shopHttp
    .post('auth/send-email-link', { email })
    .then((res) => res)
    .catch((err) => {
      throw new Error(`Ошибка авторизации продавца: ${err.message}`)
    })
  
  return authRes
}